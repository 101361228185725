import React, { FC, HTMLProps } from "react";
import { Link } from "react-router-dom";

interface ProjectItemProps {
  title: string;
  description: string;
  link: string;
  image: string;
  height: string;
}

const ProjectItem: FC<ProjectItemProps> = (props) => {
  return (
    <div className="grid bg-[#F5F5F5] rounded-xl">
      <img
        className={`rounded-t-xl object-cover ${props.height} w-full`}
        src={props.image}
        alt=""
      />
      <div className="p-4 grid gap-3 text-black items-start  justify-items-center">
        <h4 className="text-lg font-semibold mt-2">{props.title}</h4>
        <p className="leading-5 text-center">{props.description} </p>
        <button className="bg-gradient-to-r text-white from-[#222999] to-[#2832c7] max-w-fit mt-4 px-5 py-2 rounded-lg text-lg hover:scale-105">
          <Link to={`/projects/${props.link}`}>View details &rarr;</Link>
        </button>
      </div>
    </div>
  );
};

export default ProjectItem;
