import React, { FC } from "react";
import Container from "../../Components/UtilityComponents/Container";
import BlogHeaderAndText from "../../Components/BlogHeaderAndText";
import { useEffect } from "react";

const PortfolioProject: FC = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scroll(0, 0);
  }, []);

  return (
    <section className="bg-gray-900 text-white">
      <Container>
        <div className="flex flex-col gap-6">
          <>
            <h2 className="text-4xl md:text-5xl mt-4 lg:text-6xl font-medium border-b border-gray-700">
              Portfolio with React (TypeScript) and Tailwind CSS
            </h2>
            <p className="leading-6 text-lg mb-8">
              As a developer, the journey of building a personal portfolio is
              not just about showcasing skills but also about embracing new
              technologies and refining craftsmanship. Recently, I embarked on a
              project to revamp my portfolio using React with TypeScript and
              harnessing the power of Tailwind CSS. This endeavor marked a
              significant milestone in my journey as a developer, combining
              familiarity with React and TypeScript with the versatility of
              Tailwind CSS.
            </p>
          </>
          <>
            <BlogHeaderAndText
              headingTitle="The Birth of the Project"
              text="The decision to rebuild my portfolio using React with TypeScript
              and Tailwind CSS stemmed from a desire to create a dynamic and
              visually stunning representation of my work. React's
              component-based architecture and TypeScript's static typing
              provided a solid foundation for building robust and maintainable
              code. Meanwhile, Tailwind CSS offered a unique approach to
              styling, empowering me to craft custom designs with ease using
              utility classes."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Exploring React with TypeScript"
              text="Delving into React with TypeScript was a familiar yet enriching experience. While I had prior experience with React, integrating TypeScript added a new dimension to my development process. TypeScript's static type checking ensured code reliability and caught errors early in the development cycle, resulting in more stable and predictable code. The seamless integration of React with TypeScript allowed me to focus on crafting engaging user experiences without compromising on code quality."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Harnessing the Power of Tailwind CSS"
              text="Incorporating Tailwind CSS into the project was a game-changer in terms of design flexibility and efficiency. Tailwind CSS's utility-first approach enabled me to rapidly prototype and iterate on design ideas, saving valuable time without sacrificing customizability. The extensive range of utility classes empowered me to create responsive layouts and visually striking elements effortlessly, breathing life into the portfolio's design."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Lessons Learned and Future Growth"
              text="Throughout the development process, I encountered challenges and learned valuable lessons that shaped my understanding of React, TypeScript, and Tailwind CSS. Each hurdle was an opportunity for growth, leading to a deeper appreciation for the intricacies of these technologies. As I continue to refine my portfolio and explore new projects, I carry with me the insights gained from this experience, eager to push the boundaries of what's possible in web development."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Conclusion"
              text="In conclusion, crafting a dynamic portfolio with React (TypeScript) and Tailwind CSS has been a rewarding and enlightening experience. What started as a simple project evolved into a transformative journey of learning and growth. As I continue to refine my craft and pursue new opportunities, I am grateful for the lessons learned and the skills acquired along the way, ready to embrace whatever the future holds in the world of web development."
            />
          </>
        </div>
      </Container>
    </section>
  );
};

export default PortfolioProject;
