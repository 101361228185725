import React, { FC } from "react";
import imageOfMe from "../Images/image-of-me-2.jpg";
import Competences from "./Competences";
import Container from "./UtilityComponents/Container";

const About: FC = () => {
  return (
    <section id="about" className="bg-black">
      <Container>
        <div className="grid md:grid-cols-2 md:gap-14 text-white">
          <div className="w-[300px] md:w-[380px] lg:w-[480px]">
            <img
              className="h-auto w-80 md:w-full object-cover rounded-md"
              src={imageOfMe}
              alt="Quick little break while walking up in 'Rundetårn'!"
            />
          </div>
          <div className="py-6 flex justify-start flex-col">
            <h2 className="text-white mb-6 md:mb-12 font-semibold">
              A bit about me
            </h2>
            <p className="font-semibold text-base lg:text-lg mb-2">
              Software developer by day, explorer at heart (both in the real
              world and virtual landscapes!).
            </p>
            <p className="mb-4 text-base lg:text-lg">
              I bring my passion for crafting innovative solutions to my coding
              projects, while also planning adventures in nature. Whether it's
              getting lost in the beauty of a forest or gaming on a relaxing
              afternoon, I find inspiration and a chance to recharge.
            </p>
            <p className="text-base lg:text-lg">
              If you're looking for a developer who brings both dedication and a
              touch of playfulness to the table, let's connect!
            </p>
            <div className="text-1 mt-12">
              <Competences />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
