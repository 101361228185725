import React, { FC } from "react";

interface BlogHeaderAndTextProps {
  headingTitle: string;
  text: string;
}

const BlogHeaderAndText: FC<BlogHeaderAndTextProps> = (props) => {
  return (
    <div>
      <h3 className="text-2xl lg:text-4xl font-medium mb-4 mt-7">
        {props.headingTitle}
      </h3>
      <p className="leading-6 text-base md:text-lg">{props.text}</p>
    </div>
  );
};

export default BlogHeaderAndText;
