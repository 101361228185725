import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Footer: FC = () => {
  return (
    <>
      <footer className="bg-[#222999]">
        <section className="max-w-container mx-auto py-6 h-full">
          <div className="flex justify-center flex-wrap items-center opacity-75 gap-6 text-white">
            <p>© 2024 Anil Ferhat Ceran</p>
            <a
              className="hover:underline"
              href="mailto:contact@anilferhatceran.dk"
            >
              contact@anilferhatceran.dk
            </a>
            <a
              className="text-xl"
              href="https://www.linkedin.com/in/anilferhatceran/"
            >
              <span>
                <FontAwesomeIcon icon={faLinkedin} />
              </span>
            </a>
            <a className="text-xl" href="https://github.com/anilferhatceran">
              <span>
                <FontAwesomeIcon icon={faGithub} />
              </span>
            </a>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
