import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header: FC = () => {
  const location = useLocation();
  const [isBurgerMenu, setIsBurgerMenu] = useState<boolean>(false);

  const headerStyle: React.CSSProperties = {
    transition: "background-color 0.3s ease",
  };

  const handleClick = () => {
    setIsBurgerMenu((prev) => !prev);
  };

  return (
    <header
      className={
        location.pathname === "/"
          ? "bg-1 sticky top-0 z-50 "
          : "bg-[#0044A4] sticky top-0 z-50"
      }
      style={headerStyle}
    >
      <section id="header-navigation" className="max-w-container mx-auto">
        <nav className="grid grid-cols-2 py-6 text-white">
          <ul className="justify-self-start grid grid-flow-col pl-6">
            <li>
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                Anil Ferhat Ceran
              </Link>
            </li>
          </ul>
          <div className="relative md:hidden z-10 justify-self-end pr-6">
            <button aria-label="Toggle Burger Menu" onClick={handleClick}>
              <FontAwesomeIcon size="lg" icon={faBars} />
            </button>

            {isBurgerMenu && (
              <ul
                id="burger-menu"
                //   className="absolute top-full right-0
                // bg-1 p-6 z-100 grid-flow-col"
                className={
                  location.pathname === "/"
                    ? "absolute top-full right-0 bg-1 p-6 z-100 grid-flow-col"
                    : "bg-[#0044A4] absolute top-full right-0 p-6 z-100 grid-flow-col "
                }
                style={headerStyle}
                aria-label="burger-menu"
              >
                <li>
                  <Link to="/#technologies">Technologies</Link>
                  {/* <a href="/#technologies">Technologies</a> */}
                </li>
                <li>
                  <Link to="/#projects">Projects</Link>
                  {/* <a href="/#projects">Projects</a> */}
                </li>
                <li>
                  <Link to="/#about">About</Link>
                  {/* <a href="/#about">About</a> */}
                </li>
              </ul>
            )}
          </div>
          <ul className="hidden md:grid justify-self-end gap-8 grid-flow-col pr-6">
            <li>
              <Link to="/#technologies">Technologies</Link>

              {/* <a href="/#technologies">Technologies</a> */}
            </li>
            <li>
              <Link to="/#projects">Projects</Link>
              {/* <a href="/#projects">Projects</a> */}
            </li>
            <li>
              <Link to="/#about">About</Link>
              {/* <a href="/#about">About</a> */}
            </li>
          </ul>
        </nav>
      </section>
    </header>
  );
};

export default Header;
