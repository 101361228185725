import React, { ReactNode, FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ContainerProps {
  children: ReactNode;
}

const Container: FC<ContainerProps> = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // Remove '#' from the hash to get the id
      const id = location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="max-w-container mx-auto py-20 h-full border-b border-gray-700">
      {props.children}
    </div>
  );
};

export default Container;
