import React, { FC } from "react";
import landingImage from "../Images/image-of-me.jpg";
import Technologies from "../Components/Technologies";
import About from "../Components/About";
import ProjectsPage from "./Projects/ProjectsPage";

const Home: FC = (): JSX.Element => {
  return (
    <main>
      <div
        className="relative overflow-hidden h-[50vh] lg:h-[100vh] md:h-[75vh] bg-right bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${landingImage})`,
        }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.35)] bg-fixed">
          <section className="max-w-container mx-auto relative z-10 h-full flex flex-col justify-center text-white">
            <h1 className="font-semibold text-3xl md:text-5xl lg:text-7xl mb-4">
              Hi, I'm Anil Ferhat Ceran
            </h1>
            <h3>Fullstack Developer</h3>
          </section>
        </div>
      </div>
      <Technologies />
      <ProjectsPage />
      <About />
    </main>
  );
};

export default Home;
