import React, { FC } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import portfolioImage from "../../Images/portfolio-project.jpg";
import realestateImage from "../../Images/realestate-app-project.jpg";
import Container from "../../Components/UtilityComponents/Container";
import ProjectItem from "../../Components/ProjectItem";

const ProjectsPage: FC = () => {
  const location = useLocation();

  return (
    <>
      <section
        id="projects"
        className={
          location.pathname === "/projects"
            ? "bg-black h-screen"
            : "bg-black h-full"
        }
      >
        <Container>
          <div className="flex items-center justify-between mb-12">
            <h2 className="text-white italic opacity-45">
              //Projects that I have worked on
            </h2>
            {/* {location.pathname !== "/projects" ? (
              <button className="bg-gradient-to-r text-white from-[#222999] to-[#2832c7] max-w-fit mt-4 px-5 py-2 rounded-lg text-lg hover:scale-105">
                <Link to="/projects">View all projects</Link>
              </button>
            ) : null} */}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-white">
            <ProjectItem
              link="portfolio"
              title="Personal Portfolio"
              description="Personal portfolio created using React with TypeScript."
              image={portfolioImage}
              height="h-[240px]"
            />
            <ProjectItem
              link="realestateapp"
              title="Real Estate Web App"
              description="Real Estate full-stack application developed using the MERN stack. This was an academic project."
              image={realestateImage}
              height="h-[240px]"
            />
          </div>
        </Container>
      </section>
      <Outlet />
    </>
  );
};

export default ProjectsPage;
