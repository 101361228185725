import React, { FC } from "react";

const NoPage: FC = () => {
  return (
    <div>
      {" "}
      <h1 className="font-bold text-red-500 underline text-3xl">
        Error: This page does not exist
      </h1>
    </div>
  );
};

export default NoPage;
