import React, { FC } from "react";
import Container from "../../Components/UtilityComponents/Container";
import BlogHeaderAndText from "../../Components/BlogHeaderAndText";
import { useEffect } from "react";

const RealEstateProject: FC = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scroll(0, 0);
  }, []);

  return (
    <section className="bg-gray-900 text-white">
      <Container>
        <div className="flex flex-col gap-6">
          <>
            <h2 className="text-4xl md:text-5xl mt-4 lg:text-6xl font-medium border-b border-gray-700">
              Building a Real Estate Platform with the MERN Stack
            </h2>
            <p className="leading-6 text-lg mb-8">
              During my final academic year, my classmate and I took on an exam
              project—a real estate web app. He was in charge of the design and
              certain frontend components, while I handled the backend and
              client-server communication. Using the MERN stack, we dove into
              this adventure, aiming to create a user-friendly platform for
              property seekers and agents alike. Through ups and downs, we
              navigated the complexities of the MERN stack, learning as we went.
            </p>
          </>
          <>
            <BlogHeaderAndText
              headingTitle="The Birth of the Project"
              text="The idea of creating a real estate web app stemmed from a desire to provide a seamless platform for individuals seeking to buy or rent properties, as well as for real estate agents and companies to showcase their listings. With the MERN stack—MongoDB, Express.js, React, and Node.js—at our disposal, we were equipped to handle the complexities of developing a full-fledged web application from the ground up."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Empowering Users with Protected Routes"
              text="One of the key features of our real estate web app was the implementation of protected routes, ensuring secure access for different user roles. For instance, real estate agents were granted admin privileges, granting them access to an admin panel where they could perform CRUD operations—creating, updating, and deleting property listings. This level of access control not only enhanced security but also streamlined the user experience, providing a tailored interface for different user personas."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Mastering the Backend with Node.js and Express.js"
              text="Incorporating Tailwind CSS into the project was a game-changer in terms of design flexibility and efficiency. Tailwind CSS's utility-first approach enabled me to rapidly prototype and iterate on design ideas, saving valuable time without sacrificing customizability. The extensive range of utility classes empowered me to create responsive layouts and visually striking elements effortlessly, breathing life into the portfolio's design."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Client-to-Backend Communication"
              text="As the project progressed, I focused on ensuring seamless communication between the client-side React application and the backend server. Utilizing tools like Axios, I facilitated API calls to fetch and manipulate data, ensuring a responsive and dynamic user experience. I also implemented React Redux and Redux Toolkit as our state management tool. This enabled us to be more flexible regarding custom hooks etc. From handling authentication to managing state, every interaction between the client and backend was meticulously crafted to optimize performance and reliability."
            />
          </>
          <>
            <BlogHeaderAndText
              headingTitle="Conclusion"
              text="In conclusion, building a real estate platform with the MERN stack was a rewarding and enriching experience. From conceptualization to implementation, every step of the journey was filled with learning opportunities and moments of growth. As I continue my journey as a developer, I carry with me the skills, experiences, and memories from this project, ready to take on new challenges and make a meaningful impact in the world of web development."
            />
          </>
        </div>
      </Container>
    </section>
  );
};

export default RealEstateProject;
