import React, { FC } from "react";
import "../Styles/tags.css";

const Competences: FC = () => {
  return (
    <>
      <div className="flex flex-wrap gap-3">
        <h4 className="competences-item">Problem solving</h4>
        <h4 className="competences-item">Attention to detail</h4>
        <h4 className="competences-item">Flexibility & Adaptable</h4>
        <h4 className="competences-item">Analytical</h4>
        <h4 className="competences-item">Communication</h4>
        <h4 className="competences-item">Multitasking</h4>
        <h4 className="competences-item">Team skills</h4>
        <h4 className="competences-item">Independent worker</h4>
        <h4 className="competences-item">Respecting deadlines</h4>
      </div>
    </>
  );
};

export default Competences;
