import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faVuejs,
  faNodeJs,
  faLaravel,
  faWordpress,
  faHtml5,
  faCss3,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faLeaf, faCode } from "@fortawesome/free-solid-svg-icons";
import "../Styles/tags.css";
import Container from "./UtilityComponents/Container";

const Technologies: FC = () => {
  return (
    <>
      <section id="technologies" className="bg-black">
        <Container>
          <div>
            <h2 className="text-white italic opacity-45 mb-12">
              //Technologies I have experience in
            </h2>
            <div className="grid grid-cols-2 gap-5 sm:grid-cols-3  md:grid-cols-4 md:gap-6">
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faReact} />
                </span>
                React
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faVuejs} />
                </span>
                Vue
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faLeaf} />
                </span>
                MongoDB
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faLaravel} />
                </span>
                Laravel
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faNodeJs} />
                </span>
                Node JS
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faCode} />
                </span>
                .NET
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faWordpress} />
                </span>
                WordPress
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faHtml5} />
                </span>
                HTML
              </h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faCss3} />
                </span>
                CSS
              </h4>
              <h4 className="technologies-item">Tailwindcss</h4>
              <h4 className="technologies-item">
                <span className="group mr-3">
                  <FontAwesomeIcon icon={faGithub} />
                </span>
                GitHub
              </h4>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Technologies;
