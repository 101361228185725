import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import NoPage from "./Pages/NoPage";
import Home from "./Pages/Home";
import PortfolioProject from "./Pages/Projects/PortfolioProject";
import RealEstateProject from "./Pages/Projects/RealEstateProject";
import ProjectsPage from "./Pages/Projects/ProjectsPage";
import Footer from "./Components/Footer";

export default function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects">
          <Route index element={<ProjectsPage />} />
          <Route path="portfolio" element={<PortfolioProject />} />
          <Route path="realestateapp" element={<RealEstateProject />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}
